import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { ServiceGroup, ServiceMetadata } from 'app/shared/model/service-metadata';
import { AdditionalDataField, idValidatorFn, ServiceViewModel } from 'app/shared/model/service-view-model';
import { CustomValidators } from 'app/shared/validators/CustomValidators';

@Injectable()
export class GovernmentService {

  public serviceGroups: ServiceGroup[] = ['Banks',
    'Charities',
    'Finance companies and Credit Unions',
    'Financial Advisors',
    'Government',
    'Health Insurance',
    'Home and Contents (Fire & General) Insurers',
    'IWI affiliation',
    'KiwiSaver',
    'Life Insurers',
    'Local Councils',
    'Managed funds / Investment mangers',
    'Memberships',
    'Permits / Entitlements',
    'Power Companies',
    'Public Libraries',
    'Share Registries',
    'Subscriptions',
    'Utilities'
  ];

  public getServicesViewModel(services: ServiceMetadata[]): ServiceViewModel[] {
    if (services) {
      return services.map(metaData => {
        const options = metaData.idRequired ? [{ key: 'didnotuse',  value: 'Didn’t use this service'}] : [{ key: 'unsurenumber',  value: `Notify without ${metaData.fieldLabel}`},
          { key: 'didnotuse',  value: 'Didn’t use this'}];
        let idFormControlValidator: ValidatorFn = CustomValidators.policyNumber;
        let idValidator: idValidatorFn = CustomValidators.policyNumberValid;
        if (metaData.idValidator === 'BANK') {
          idFormControlValidator = CustomValidators.bankAccountNumber;
          idValidator = CustomValidators.bankAccountNumberValid;
        } else if (metaData.idValidator === 'IRD') {
          idFormControlValidator = CustomValidators.irdNumber;
          idValidator = CustomValidators.irdNumberValid;
        } else if (metaData.idValidator === 'PASSPORT') {
          idFormControlValidator = CustomValidators.passportNumber;
          idValidator = CustomValidators.passportNumberValid;
        } else if (metaData.idValidator === 'POLICY_ASTERON') {
          idFormControlValidator = CustomValidators.asteronPolicyNumber;
          idValidator = CustomValidators.asteronPolicyNumberValid;
        }
        let additionalDataFields: AdditionalDataField[] = [];
        if (metaData.additionalDataFields) {
          additionalDataFields = metaData.additionalDataFields.map(metaField => {
            return {
              fieldLabel: metaField.fieldLabel,
              fieldValueChoices: metaField.fieldValueChoices,
            };
          });
        }
        const model: ServiceViewModel = new ServiceViewModel({
          code: metaData.code,
          name: metaData.name,
          nameDisplaySuffix: metaData.nameDisplaySuffix,
          notes: metaData.notes,
          group: metaData.group,
          hint: metaData.hint,
          fieldLabel: metaData.fieldLabel,
          fieldPlaceholder: metaData.fieldPlaceholder,
          idFormControlValidator: idFormControlValidator,
          idValidator: idValidator,
          options: options,
          additionalDataFields: additionalDataFields,
          freeTextLabel: metaData.freeTextLabel,
          freeTextHint: metaData.freeTextHint,
        });
        return model;
      });
    }
    return [];
  }

  public getInstructions(serviceGroup: ServiceGroup): string | undefined {
    if (serviceGroup === 'KiwiSaver') {
      /* eslint-disable max-len */
      return `<p>You can only select one KiwiSaver provider. If the KiwiSaver Provider is a Bank, please select the Bank in the section above. If you do not know who the KiwiSaver Provider is, <a href="https://www.ird.govt.nz/contactus/general-enquiries" target="_blank">contact IRD</a> to find out who the Provider is.  Note, IRD will only be able to disclose this information to a person who can prove they are the Legal Executor or Administrator for the Estate.</p>`;
    }
    return undefined;
  }

}
