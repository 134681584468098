import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import sha1 from 'crypto-js/sha1';
import { environment } from '../../environments/environment';
import { Customer, TeamType } from 'app/shared/model/customer';

@Injectable()
export class AuthenticationService {

    private claims: any;
    private customer: Customer;

    constructor(
      public afAuth: AngularFireAuth,
      private http: HttpClient,
      private logService: LogService
    ) { }

    getUser(): Observable<firebase.default.User> {
        return this.afAuth.authState;
    }

    get email(): string {
      if (this.claims) {
        return this.claims.email;
      }
      return '';
    }

    get team(): string {
      if (this.claims) {
        return this.claims.team;
      }
      return '';
    }

    get teamType(): TeamType {
      if (this.claims) {
        return this.claims.teamType || 'recipient';
      }
      return 'recipient';
    }

    get isAdmin(): boolean {
      return this.team === 'MYTROVE';
    }

    get isNotifyAllowed(): boolean {
      if (this.claims && this.claims.teamType) {
        return this.claims.teamType === 'lawFirm';
      }
      return false;
    }  

    get isDataExportAllowed(): boolean {
      if (this.claims) {
        return this.claims.data_export_allowed || false;
      }
      return false;
    }

    setClaims(claims: any) {
      this.claims = claims;
    }

    setCustomer(customer: Customer) {
      this.customer = customer;
    }

    isEmailSentOnStatus(status: string): boolean {
      if (this.teamType === 'recipient') {
        if (status === 'NO MATCH' || status === 'SOLE ESTATE'  || status === 'JOINT HOLDER') {
          return true;
        } else if (status === 'DONE' && this.customer && this.customer.doneEmailTemplate && this.customer.doneEmailTemplate.length > 0) {
          return true;
        }
      }
      return false;
    }

    async signIn(email: string, password: string): Promise<firebase.default.User> {
        return this.afAuth.signInWithEmailAndPassword(email, password).then(credentials => {
          this.logService.log(`Sign in as ${credentials.user.email}`);
          return credentials.user;
        }).catch(error => {
          this.logService.error(`Sign in error ${error.code}`);
          return undefined;
        });
    }

    async signOut() {
      try {
        const user = await this.afAuth.currentUser;
        const idToken = await user.getIdToken();
        this.claims = undefined;
        this.customer = undefined;
        const url = environment.functionsURL + `/userToken/${idToken}`;
        this.logService.log(`Calling ${url} DELETE with param ${idToken}`);
        await this.http.delete(url).toPromise();
        await this.afAuth.signOut();
      } catch (error) {
        this.logService.error(`Sign out error ${error.code}`);
        await this.afAuth.signOut();
      }
    }

    getFirstName(user): string {
      if (user.displayName) {
        const names = user.displayName.split(' ');
        const lastName = names.pop();
        if (names.length > 0) {
          return names[0];
        }
      }
      return '';
    }

    getLastName(user): string {
      if (user.displayName) {
        const names = user.displayName.split(' ');
        return names.pop();
      }
      return '';
    }

    getClaims(user: firebase.default.User): Promise<any> {
      return user.getIdTokenResult(true).then(result => {
        this.logService.log(`Claims: ${JSON.stringify(result.claims, null, 4)}`);
        return result.claims;
      });

    }

    async isKnownUser(email: string): Promise<boolean> {
      const methods = await this.afAuth.fetchSignInMethodsForEmail(email);
      return methods.indexOf('password') >= 0;
    }

    async createUser(email: string, password: string, displayName: string): Promise<firebase.default.User> {
      let user;
      return this.afAuth.createUserWithEmailAndPassword(email, password).then(credentials => {
        this.logService.log(`User created for ${credentials.user.email}`);
        user = credentials.user;
        return credentials.user.updateProfile({displayName: displayName});
      }).then(() => {
        return user;
      }).catch(error => {
        this.logService.error(`Failed to crate user: ${error.code}`);
        return undefined;
      });
    }

    resetPassword(email: string): Promise<any> {
      return this.afAuth.sendPasswordResetEmail(email);
    }

    async isPwned(password: string): Promise<boolean> {
      try {
        const hashedPassword = sha1(password);
        this.logService.log(`SHA1: ${hashedPassword}`);
        const url = `https://api.pwnedpasswords.com/range/${hashedPassword.toString().substr(0, 5)}`;
        this.logService.log(`Calling ${url}`);
        const possibleMatches = await this.http.get(url, {
          responseType: 'text'
        }).toPromise();
        this.logService.log(`possibleMatches ${possibleMatches}`);
        return possibleMatches.indexOf(hashedPassword.toString().toUpperCase().substr(5)) >= 0;
      } catch (error) {
        this.logService.error(`Failed to check for pwned password: ${JSON.stringify(error, null, 4)}`);
        return false;
      }
    }
}
